<template>
  <section ref="resourcesListParentEl" class="section-resources-list">
    <header v-if="title === 'Popular right now'">
      <popular-title :title="title" />
    </header>
    <header v-else-if="title || subtitle" :class="['row', 'text-' + align]">
      <div class="columns small-12">
        <div v-if="link" class="flex align-middle flex-wrap">
          <h2 v-if="title && isHomepage" class="heading-2">{{ title }}</h2>
          <h2 v-if="title && !isHomepage" class="tw-text-xl md:tw-text-2xl">{{ title }}</h2>
          <t-link-button
            v-if="link && !isHomepage"
            :href="localePath(link)"
            size="sm"
            variant="default"
            class="tw-ml-auto tw-border-none !tw-shadow-none"
            title="Click to view more"
          >
            <span class="tw-text-sm">View more &rarr;</span>
          </t-link-button>
        </div>

        <h2 v-else-if="title && isHomepage" class="heading-2">
          {{ title }}
        </h2>
        <h2 v-else-if="title && !isHomepage" class="tw-text-xl md:tw-text-2xl">
          {{ title }}
        </h2>
        <p v-if="subtitle && !isHomepage">{{ subtitle }}</p>
        <p v-if="subtitle && isHomepage" class="subheading">{{ subtitle }}</p>
      </div>
    </header>

    <div v-if="availableFilters" class="row row-filters">
      <div class="columns small-12">
        <resource-list-filters
          :available-filters="availableFilters"
          :default-params="params"
          :search-query="searchQuery"
          :filter-dropdown-count="numberOfFilters || filterDropdownCount"
          :order-by="paramsClone.orderBy"
          :list-type="listType"
          @update-filters="updateFilters"
          @update-order-by="updateOrderBy"
        />
      </div>
    </div>

    <div
      v-if="listType !== 'related' && size && size > 0 && (pagination || loadMore)"
      class="row small text-mute"
    >
      <div
        :class="{
          'columns small-12 tw-mb-2': parentResource?.type !== 'teaching-resource',
          'tw-m-2': parentResource?.type === 'teaching-resource',
        }"
      >
        <template v-if="size > resources.length">
          {{ resources.length }} of {{ numberWithCommas(size) }}
        </template>
        <template v-else>{{ resources.length }}</template>
        teaching {{ pluralise('resource', size) }} for those 'aha' moments
      </div>
    </div>
    <div
      :class="{
        'row tw-mx-[-0.75rem]': parentResource?.type !== 'teaching-resource',
        'tw-mx-auto tw-flex tw-max-w-8xl tw-flex-wrap':
          parentResource?.type === 'teaching-resource',
      }"
    >
      <div
        :class="{
          'tw-w-full tw-px-6 md:tw-px-10': parentResource?.type === 'teaching-resource',
          'columns small-12': parentResource?.type !== 'teaching-resource',
        }"
      >
        <ul
          v-if="$fetchState.pending || (isLoading && page === 1)"
          :class="[
            'row',
            'block-grid',
            'block-grid-cards',
            rowsPerBreakpoint,
            {
              'tw-mx-auto tw-flex tw-max-w-8xl tw-flex-wrap':
                parentResource?.type === 'teaching-resource',
            },
          ]"
        >
          <li v-for="item in [...Array(placeHolders).keys()]" :key="item">
            <div class="card skeleton">
              <div></div>
              <h4></h4>
              <h3></h3>
              <p></p>
            </div>
          </li>
        </ul>
        <template v-else-if="resources && resources.length > 0">
          <ul v-if="categories && categories.length > 0" class="inline-list category-list">
            <li v-for="category in categories" :key="category.link">
              <nuxt-link :to="category.link" class="tag-label">{{ category.name }}</nuxt-link>
            </li>
          </ul>

          <ul
            :class="[
              'row',
              'block-grid',
              'block-grid-cards',
              rowsPerBreakpoint,
              {
                'tw-mx-auto tw-flex tw-max-w-8xl tw-flex-wrap':
                  parentResource?.type === 'teaching-resource',
              },
            ]"
          >
            <li v-for="resource in resources" :key="resource.id">
              <card
                type="browse"
                :item="resource"
                :show-author="resource.type === 'podcast' || resource.type === 'blog'"
                :amplitude-events="amplitudeEvents"
                :show-excerpt="true"
                :clamp-title="5"
                @click="
                  resource?.type == 'teaching-resource' ? trackRecommendationCardClick() : null
                "
              />
            </li>
          </ul>
        </template>

        <div v-else-if="$fetchState.error" class="callout callout-error">
          {{ $fetchState.error.message }}
        </div>

        <div v-else-if="searchQuery" class="flex align-middle align-center">
          <strong class="ts-500">
            Oops! There were no results for '{{ searchQuery }}' with your selected filters.
          </strong>
        </div>
        <div v-else-if="listType !== 'related'" class="callout">No resources found</div>

        <div v-if="loadMore && page < totalPages" class="text-center" style="width: 100%">
          <button v-if="isLoading" class="button button-block" disabled>Loading...</button>
          <button v-else class="button button-tertiary" @click="loadResources(page + 1, true)">
            Load more
          </button>
        </div>
        <pagination
          v-if="pagination && totalPages > 1"
          :total-pages="totalPages"
          :current-page="page"
          :size="size"
          :category="params.category"
          @click="scrollToTop"
        />
      </div>
    </div>
  </section>
</template>

<script>
import PopularTitle from '../homepage/PopularTitle.vue';
import { numberWithCommas, pluralise } from '~/utils/formatStrings';
import Card from '~/components/card/Card.vue';
import TLinkButton from '~/components/atoms/t-link-button.vue';

export default {
  name: 'ResourcesList',
  components: {
    Card,
    PopularTitle,
    TLinkButton,
    Pagination: () => import('~/components/Pagination.vue'),
    ResourceListFilters: () => import('~/components/resource/ResourceListFilters.vue'),
  },
  props: {
    align: { type: String, default: 'left' },
    colCount: { type: Number, default: 5 },
    color: { type: String, default: '' },
    availableFilters: { type: Array, default: null },
    filterDropdownCount: { type: Number, default: 2 }, // How many filters are not contained in the 'More Filters' dropdown
    loadMore: { type: Boolean, default: false },
    link: { type: String, default: '' }, // Optional button link
    listType: { type: String, default: 'resources' },
    pagination: { type: Boolean, default: false },
    params: {
      type: Object,
      default() {
        return {};
      },
    },
    subtitle: { type: String, default: '' },
    searchQuery: { type: String, default: '' }, // Watch and update results if new search query passed to component
    title: { type: String, default: '' },
    isHomepage: { type: Boolean, default: false },
    amplitudeEvents: {
      type: Object,
      default() {
        return {};
      },
    },
    parentResource: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      paramsClone: JSON.parse(JSON.stringify(this.params)), // A local clone of the default params
      isLoading: false,
      placeHolders: 20, // Number of placeholder items to display while loading
      page: 1,
      resources: [],
      categories: [],
      size: 0,
      totalPages: 0,
      stringParams: ['page', 'query', 'sort'],
    };
  },
  async fetch() {
    // Filter resources based on query params in the URL
    const queryParams = this.$route.query ? this.$route.query : null;

    if (queryParams) {
      // Force filter params to always be arrays. Exclude anything in stringParams
      for (const key in queryParams) {
        const param = queryParams[key];

        if (param && !Array.isArray(param) && !this.stringParams.includes(key)) {
          queryParams[key] = [param];
        }
      }

      this.paramsClone = Object.assign(this.paramsClone, queryParams);
    }

    if (this.$route.query.page !== this.page) {
      await this.loadResources(this.$route.query.page ?? 1, false);
    }
  },
  fetchKey: 'resource-list',
  computed: {
    rowsPerBreakpoint() {
      // Large uses the colCount (default 5)
      // Medium is one less than colCount
      // Small  is usually 1 column, but related resources can be 2
      const small = this.listType === 'related' ? 'small-up-2' : 'small-up-1';
      return small + ' medium-up-' + (this.colCount - 1) + ' large-up-' + this.colCount;
    },
    numberOfFilters() {
      if (this.$route.name.match(/curriculum-v9/)) {
        return 4;
      } else {
        return undefined;
      }
    },
  },
  watch: {
    // Important: allows pagination to work
    '$route.query.page'() {
      if (this.pagination) {
        this.$fetch();
      }
    },
    searchQuery(newVal, oldVal) {
      // Update results when search query changes
      if (newVal !== oldVal) {
        this.$fetch();
      }
    },
  },
  methods: {
    numberWithCommas,
    pluralise,
    async loadResources(page = 1, append = false) {
      // Handle different types of resources from different endpoints
      const action = this.listType === 'related' ? 'loadRelatedResources' : 'loadResources';
      const params = this.paramsClone;

      // For pagination+
      params.page = page;

      // Fallback country
      if (!params.country) {
        params.country = this.$i18n.locale;
      }

      // Remap params for API: query -> search
      if (this.searchQuery) {
        params.search = this.searchQuery;
        delete params.query;
      }

      // Remap params for API: sort -> orderBy
      if (params.sort) {
        params.orderBy = params.sort;
        delete params.sort;
      }

      // Fallback for sorting
      if (!params.orderBy) {
        params.orderBy = 'popularity_1_week';
      }

      // Disable the Load More button until new items loaded
      this.isLoading = true;

      try {
        // Return list instead of adding to store because we might have multiple resource list components on the page
        const response = await this.$store.dispatch(action, params);
        this.size = response.size;
        this.totalPages = response.totalPages;
        this.page = response.page;

        // Append to list of resources
        this.resources = append ? this.resources.concat(response.list) : response.list;
        this.categories = response.categories ? response.categories : [];
      } catch (err) {
        throw new Error(err);
      } finally {
        this.isLoading = false;
      }
    },
    updateFilters(filterParams) {
      // Add the filter params to this query
      // If no filterParams supplied then reset filters to default
      this.paramsClone = filterParams
        ? Object.assign(this.paramsClone, filterParams)
        : JSON.parse(JSON.stringify(this.params));
      this.loadResources(filterParams?.page ? parseInt(filterParams.page, 10) : 1);
    },
    updateOrderBy(orderBy) {
      this.paramsClone.orderBy = orderBy;
      this.loadResources();
    },
    scrollToTop() {
      if (!this.$refs.resourcesListParentEl) {
        return;
      }

      const element = this.$refs.resourcesListParentEl;
      const headerAndBannersHeight = -300;
      const yOffset =
        element.getBoundingClientRect().top + window.pageYOffset + headerAndBannersHeight;
      window.scrollTo({ top: yOffset, behavior: 'smooth' });
    },
    trackRecommendationCardClick() {
      this.$amplitude.track('Resource | Page Clicked', {
        Click_Type: 'Recommended card',
        ...this.amplitudeEvents,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
header {
  h2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  p {
    margin-top: 0;
  }
}

.subheading,
.heading-2 {
  text-align: center;
  margin: 0 auto;
}

.subheading {
  padding-bottom: 2.5rem;
  padding-top: 1rem;
}

.heading-2 {
  max-width: 16.5rem;
}

.section-home {
  background: $grey-0;
  border-radius: $base-padding * 2;
  padding: $base-padding * 3.5;
  margin: 3rem 1rem;
}

.category-list {
  margin-top: $base-padding;

  .button-link {
    background-color: $grey-0;
    color: inherit;

    &:hover,
    &:focus {
      background-color: $grey-100;
      text-decoration: none;
    }
  }
}

@media #{$small-up} {
  .heading-2 {
    max-width: none;
  }
}

// Remove line-clamp in cards if only one item per line
@media #{$small-only} {
  .small-up-1 {
    :deep(.line-clamp) {
      height: auto;
    }
  }
  .section-home {
    margin: 0 2rem 2rem;
    padding: $base-padding * 2;
  }

  .category-list {
    display: flex;
    overflow-x: auto;
  }
}

@media #{$small} {
  .section-home {
    padding: 4rem;
    margin: 4rem;
  }
}
</style>
